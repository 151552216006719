@use '~/scss/main.scss';

.panel {
  padding-top: main.space(20);
  padding-bottom: main.space(20);
  background-color: lch(98% 5 276);
}

.headingBox {
  @include main.content;
}

.heading {
  margin-top: main.space(1);
  margin-bottom: main.space(1);
  font-size: main.font-scale-maj(3);
  font-weight: main.$font-weight-heavy;
}

.cardGrid {
  @include main.content;

  display: flex;
  flex-direction: column;

  :last-child {
    border-bottom: none;
  }
}
