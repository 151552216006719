@use '~/scss/main.scss';

.box {
  display: flex;
  flex-direction: column;
  padding-top: main.space(6);
  padding-bottom: main.space(6);
  border-bottom: solid 1px main.$color-cool-gray-20;
  gap: main.space(2);
}

.date {
  color: main.$color-cool-gray-80;
  font-size: main.$font-size-smaller;
  letter-spacing: main.$letter-spacing-tight;
}

/*
.meta {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: main.space(2);

  height: min-content;
}
*/

.block {
  height: 6px;
  aspect-ratio: 1/1;
  background-color: main.$ultra;
}

.info {
  display: flex;
  flex-direction: column;
  gap: main.space(1);
}

#title {
  font-family: main.$secondary;
  font-size: main.font-scale-maj(2);
  font-weight: main.$font-weight-heavy;
  text-wrap: balance;

  a {
    text-decoration: none;
  }
}

.description {
  width: 60ch;
  color: main.$color-cool-gray-100;
  font-size: 16px;
  line-height: 1.4;
  text-wrap: balance;

  @media (min-width: main.$tablet) {
    font-size: 17px;
  }

  @media (min-width: main.$desktop) {
    font-size: main.font-scale-maj(1);
  }
}

.tags {
  display: flex;
  margin-bottom: main.space(1);
  font-family: main.$mono;
  font-size: 12px;
  gap: main.space(1);
  grid-area: tags;
}

.read {
  display: flex;
  align-items: center;
  font-size: main.$font-size-smaller;
  gap: main.space(1);
  grid-area: read;
}
