/*************************************************
 * Utilities
 ************************************************/
// @function pow($base, $exponents)
// Helper: Raise a number the power of another number
// Adapted from: https://gist.github.com/hail2u/1964056
//
// @param {number} $base — The base number
// @param {number} $exponents — The exponent to which to raise $base
@function pow($base, $exponents) {
  @if $exponents == 0 {
    @return 1;
  }

  $raised: 1;
  @for $i from 1 through $exponents {
    $raised: $raised * $base;
  }
  @return $raised;
}

/*************************************************
 * Color
 ************************************************/
$transparent: hsla(0, 0%, 0%, 0);

// grayscale

@function gray($step) {
  @return hsla(0, 0%, 100 - 10 * $step);
}

// colors
$cultured: hsla(40, 30%, 98%, 1);
$cultured-20: hsla(40, 30%, 95%, 1);
$blue: 221;
$green: 140;

@function color($base-color, $step) {
  @return hsla($base-color, 70%, calc(100 - $step * 10), 1);
}

// Red Variations
$color-red-100: #2d0709;
$color-red-90: #520408;
$color-red-80: #750e13;
$color-red-70: #a2191f;
$color-red-60: #da1e28;
$color-red-50: #fa4d56;
$color-red-40: #ff8389;
$color-red-30: #ffb3b8;
$color-red-20: #ffd7d9;
$color-red-10: #fff1f1;

// Magenta Variations
$color-magenta-100: #2a0a18;
$color-magenta-90: #510224;
$color-magenta-80: #740937;
$color-magenta-70: #9f1853;
$color-magenta-60: #d02670;
$color-magenta-50: #ee5396;
$color-magenta-40: #ff7eb6;
$color-magenta-30: #ffafd2;
$color-magenta-20: #ffd6e8;
$color-magenta-10: #fff0f7;

// Purple Variations
$color-purple-100: #1c0f30;
$color-purple-90: #31135e;
$color-purple-80: #491d8b;
$color-purple-70: #6929c4;
$color-purple-60: #8a3ffc;
$color-purple-50: #a56eff;
$color-purple-40: #be95ff;
$color-purple-30: #d4bbff;
$color-purple-20: #e8daff;
$color-purple-10: #f6f2ff;

// Blue Variations
$color-blue-100: #001141;
$color-blue-90: #001d6c;
$color-blue-80: #002d9c;
$color-blue-70: #0043ce;
$color-blue-60: #0f62fe;
$color-blue-50: #4589ff;
$color-blue-40: #78a9ff;
$color-blue-30: #a6c8ff;
$color-blue-20: #d0e2ff;
$color-blue-10: #edf5ff;

// Cyan Variations
$color-cyan-100: #061727;
$color-cyan-90: #012749;
$color-cyan-80: #003a6d;
$color-cyan-70: #00539a;
$color-cyan-60: #0072c3;
$color-cyan-50: #1192e8;
$color-cyan-40: #33b1ff;
$color-cyan-30: #82cfff;
$color-cyan-20: #bae6ff;
$color-cyan-10: #e5f6ff;

// Teal Variations
$color-teal-100: #081a1c;
$color-teal-90: #022b30;
$color-teal-80: #004144;
$color-teal-70: #005d5d;
$color-teal-60: #007d79;
$color-teal-50: #009d9a;
$color-teal-40: #08bdba;
$color-teal-30: #3ddbd9;
$color-teal-20: #9ef0f0;
$color-teal-10: #d9fbfb;

// Green Variations
$color-green-100: #071908;
$color-green-90: #022d0d;
$color-green-80: #044317;
$color-green-70: #0e6027;
$color-green-60: #198038;
$color-green-50: #24a148;
$color-green-40: #42be65;
$color-green-30: #6fdc8c;
$color-green-20: #a7f0ba;
$color-green-10: #defbe6;

// Cool Gray Variations
$color-cool-gray-100: #121619;
$color-cool-gray-90: #21272a;
$color-cool-gray-80: #343a3f;
$color-cool-gray-70: #4d5358;
$color-cool-gray-60: #697077;
$color-cool-gray-50: #878d96;
$color-cool-gray-40: #a2a9b0;
$color-cool-gray-30: #c1c7cd;
$color-cool-gray-20: #dde1e6;
$color-cool-gray-10: #f2f4f8;

// Gray Variations
$color-gray-100: #161616;
$color-gray-90: #262626;
$color-gray-80: #393939;
$color-gray-70: #525252;
$color-gray-60: #6f6f6f;
$color-gray-50: #8d8d8d;
$color-gray-40: #a8a8a8;
$color-gray-30: #c6c6c6;
$color-gray-20: #e0e0e0;
$color-gray-10: #f4f4f4;

// Warm Gray Variations
$color-warm-gray-100: #171414;
$color-warm-gray-90: #272525;
$color-warm-gray-80: #3c3838;
$color-warm-gray-70: #565151;
$color-warm-gray-60: #726e6e;
$color-warm-gray-50: #8f8b8b;
$color-warm-gray-40: #ada8a8;
$color-warm-gray-30: #cac5c4;
$color-warm-gray-20: #e5e0df;
$color-warm-gray-10: #f7f3f2;

// Base Colors
$color-black: #000000;
$color-white: #ffffff;

/*************************************************
 * Typography
 ************************************************/

// families
$sans: var(--font-sans), sans-serif;
$secondary: var(--font-secondary), sans-serif;
$mono: var(--font-mono), monospace;

// weights
$font-weight-heavy: 600;
$font-weight-regular: 400;

// scales
$base-font-size: 1rem;
$font-ratio: 1.414;
$font-size-smaller: 16px;

@function font-size($step) {
  @return $base-font-size * pow($font-ratio, $step - 1);
}

@function font-scale-min($step) {
  @return $base-font-size * pow(1.25, $step - 1);
}

@function font-scale-maj($step) {
  @return $base-font-size * pow(1.333, $step - 1);
}

// letter spacing
$letter-spacing-tight: -0.02em;

/*************************************************
 * Spacing
 ************************************************/

// 10px space scale
$base-space-unit: 10px;
@function space($step) {
  @return $base-space-unit * $step;
}

/*************************************************
 * Tokens
 ************************************************/
$line-height: 1.4211;

@mixin code {
  // do not bleed white highlight into background white
  //border: solid 1px colors.$cool-gray-20;
}

/*************************************************
 * Other
 ************************************************/

/*
@mixin svg-hover-color($color) {
  &:hover {
    svg {
      color: $color;
    }
  }
}
*/

@mixin scrollbar(
  $size: 4px,
  $foreground-color: hsl(0, 0, 30),
  $background-color: rgba(0, 0, 0, 0)
) {
  // For Chrome & Safari
  &::-webkit-scrollbar {
    width: $size;
    height: $size;
  }
  &::-webkit-scrollbar-thumb {
    background: $foreground-color;
    border-radius: space(1);
  }
  &::-webkit-scrollbar-track {
    background: $background-color;
    border-radius: space(1);
  }

  // Standard version (Firefox only for now)
  scrollbar-color: $foreground-color $background-color;
}

/******************************************************************************
 * FINAL
 ******************************************************************************/

// control width of any element to prevent long lines
@mixin content {
  max-width: 345px;
  margin: 0 auto;

  @media (min-width: 734px) {
    max-width: 690px;
  }

  @media (min-width: 1441px) {
    max-width: 980px;
  }
}

@mixin article-content {
  max-width: 345px;
  margin: 0 auto;

  @media (min-width: 734px) {
    max-width: 576px;
  }

  @media (min-width: 1068px) {
    max-width: 653px;
  }
}

$tablet: 768px;
$desktop: 1024px;

@mixin full-width {
  /*
  @media (min-width: $tablet) {
    max-width: 60rem;
    margin-left: auto;
    margin-right: auto;
  }
    */
}

@mixin twelve-grid {
  display: grid;
  padding-right: 20px;
  padding-left: 20px;
  gap: 10px;
  grid-template-columns: repeat(12, 1fr);
}

// ui text remains constant at 14px
@mixin ui {
  font-size: 15px;
}

@mixin tag {
  @include ui;

  background-color: hsl(240, 28, 95);
  color: hsl(240, 100, 30);

  font-family: $sans;
  font-weight: 400;
  text-transform: lowercase;
}

@mixin text {
  font-family: $sans;
  font-size: 16px;

  @media (min-width: 734px) {
    font-size: 18px;
  }

  @media (min-width: 1024px) {
    font-size: 19px;
  }
}

@mixin heading-1 {
  font-size: 32px;
  font-weight: 300;

  @media (min-width: 734px) {
    font-size: 40px;
  }

  @media (min-width: 1440px) {
    font-size: 48px;
  }
}

@mixin heading-2 {
  font-family: $sans;
  font-size: 21px;
  font-weight: 400;

  @media (min-width: 1440px) {
    font-size: 24px;
  }
}

@mixin brand {
  font-weight: 500;
  letter-spacing: -0.02em;
}

@mixin bold {
  font-family: $sans;
  letter-spacing: -0.03em;
}

$color-fade: hsl(0, 0, 70);
$color-secondary: hsl(0, 0, 80);
$color-primary: hsl(0, 0, 0);

$black: hsl(0, 0, 0);
$white: hsl(0, 0, 100);
$dark-gray: hsl(0, 0, 25);
$light-gray: hsl(0, 0, 75);
$gray-dark: hsl(0, 0, 25);
$gray-light: hsl(0, 0, 75);
//$seashell: lch(97% 5 276 / 1);
//$seashell: lch(89% 16 264);
$seashell: lch(96% 6 256);
$lily: hsl(20, 50, 98);
// TODO rename this and convert to rgb for use in cards
$lily-darker: hsl(20, 55, 97);
$azure: hsl(215, 80, 55);
$azure-light: hsl(215, 80, 85);
$ultra: hsl(240, 100, 65);
$ultra-light: hsl(240, 100, 80);
$indigo: hsl(265, 100, 50);
$indigo-light: hsl(265, 100, 85);
$folly: hsl(350, 100, 65);
$folly-light: hsl(350, 100, 80);
$cosmos: hsl(240, 50, 15);

$content-bound: 65%;
