@use '~/scss/main.scss';

.panel {
  padding-top: main.space(9);
  background-color: main.$color-white;
  color: main.$black;

  @media (min-width: main.$tablet) {
    padding-top: main.space(12);
  }

  @media (min-width: main.$desktop) {
    padding-top: main.space(16);
  }
}

.content {
  @include main.content;

  display: grid;
  grid-template-areas:
    'brand brand'
    'nav   links'
    'copy  copy ';
  grid-template-columns: 1fr 1fr;

  @media (min-width: main.$tablet) {
    grid-template-areas:
      'brand nav  links'
      'copy  copy copy';
    grid-template-columns: 2fr 1fr 1fr;
  }
}

.nav {
  display: flex;
  flex-direction: column;
  margin-top: main.space(4);
  font-size: main.$font-size-smaller;
  gap: main.space(1);
  grid-area: nav;

  a {
    color: main.$color-gray-80;
    text-decoration: none;
  }

  @media (min-width: main.$tablet) {
    margin-top: 0;
  }
}

#links {
  display: flex;
  flex-direction: column;
  font-family: main.$sans;
  font-size: main.$font-size-smaller;
  grid-area: links;
  row-gap: main.space(1);

  a {
    color: main.$color-gray-80;
    text-decoration: none;
  }
}

.copyright {
  padding-top: main.space(1);
  padding-bottom: main.space(1);
  border-top: solid 1px main.$color-gray-40;
  margin-top: main.space(10);
  color: main.$color-gray-80;
  font-size: main.$font-size-smaller;
  grid-area: copy;
}

.brand {
  grid-area: brand;

  @include main.brand;
}

.heading {
  margin-bottom: main.space(1);
  font-size: main.$font-size-smaller;
  font-weight: main.$font-weight-heavy;
}
