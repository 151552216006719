@use '~/scss/main.scss';

.panel {
  padding-top: main.space(20);
  padding-bottom: main.space(20);
  background-image: linear-gradient(
    150deg,
    main.$color-blue-10,
    main.$color-purple-10
  );
}

.content {
  @include main.content;
}

.info {
  display: flex;
  flex-direction: column;
  font-size: main.font-scale-maj(1);
  font-weight: main.$font-weight-heavy;
  gap: main.space(1);
  text-align: center;
}

.title {
  font-weight: main.$font-weight-heavy;
  letter-spacing: main.$letter-spacing-tight;
  text-align: center;
  text-wrap: balance;

  @media (min-width: main.$tablet) {
    font-size: 24px;
  }

  @media (min-width: main.$desktop) {
    margin-bottom: main.space(8);
    font-size: main.font-scale-maj(4);
  }
}

.sub {
  font-family: main.$sans;
  font-size: 20px;

  @media (min-width: main.$tablet) {
    font-size: 20px;
  }

  @media (min-width: main.$desktop) {
    font-size: main.font-scale-maj(2);
  }
}
