@use '~/scss/main.scss';

.panel {
  position: sticky;
  z-index: 999;
  top: 0;
  left: 0;
  border-bottom: solid 1px main.$color-cool-gray-10;
  backdrop-filter: saturate(180%) blur(20px);
}

.background {
  padding-top: main.space(2);
  padding-bottom: main.space(2);
  background-color: lch(98% 5 276);
  opacity: 0.8;
}

.content {
  @include main.content;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: main.space(1);
  opacity: 1;
}

.menu {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: main.space(4);
}

.brand {
  a {
    @include main.brand;

    font-size: 16px;
  }

  @media (min-width: main.$tablet) {
    a {
      font-size: 17px;
    }
  }

  @media (min-width: main.$desktop) {
    a {
      font-size: main.font-scale-maj(1);
      line-height: 0;
    }
  }
}

.nav {
  @include main.ui;

  display: flex;
  gap: main.space(2);

  a {
    font-family: main.$sans;
    font-weight: 400;
  }

  @media (min-width: main.$tablet) {
    gap: main.space(2);
  }
}

.contact {
  @include main.ui;

  font-weight: main.$font-weight-heavy;
}
